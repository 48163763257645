import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Upload: { input: any; output: any };
};

export type Address = {
  /** Name of city or city district (ex. København K) */
  city: Scalars['String']['output'];
  /** Residence door if building or floor has multiple doors (ex. TH) */
  door?: Maybe<Scalars['String']['output']>;
  /** Residence floor if building has multiple floors (ex. 1.) */
  floor?: Maybe<Scalars['String']['output']>;
  /** House number on street (ex. 24A) */
  houseNumber: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  /** City postal code (ex. 2800) */
  postalCode: Scalars['String']['output'];
  /** Name of street without house number (ex. Vesterbrogade) */
  street: Scalars['String']['output'];
};

export type AddressInput = {
  /** Name of city or city district (ex. København K) */
  city: Scalars['String']['input'];
  /** Residence door if building or floor has multiple doors (ex. TH) */
  door?: InputMaybe<Scalars['String']['input']>;
  /** Residence floor if building has multiple floors (ex. 1.) */
  floor?: InputMaybe<Scalars['String']['input']>;
  /** House number on street (ex. 24A) */
  houseNumber: Scalars['String']['input'];
  /** City postal code (ex. 2800) */
  postalCode: Scalars['String']['input'];
  /** Name of street without house number (ex. Vesterbrogade) */
  street: Scalars['String']['input'];
};

export type AllOfferSavings = {
  disclaimer: Scalars['String']['output'];
  residence?: Maybe<SimpleResidenceResult>;
  results: Array<OfferSavingsWithProducts>;
};

export type AssortedOfferInput = {
  assortedProducts: Array<ProductInput>;
  expenses?: InputMaybe<Array<OfferExpenseInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reservations?: InputMaybe<Scalars['String']['input']>;
};

export type AssortedProduct = {
  brand: Scalars['String']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  size: HeatPumpSize;
};

export type AssortedProductInput = {
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type AuthProvider = {
  type: AuthProviderType;
  userID: Scalars['String']['output'];
};

export type AuthProviderType = 'google';

export type BankCustomerLead = {
  address: AddressInput;
  /** Bank branch at which customer advisor works */
  advisorBranch?: InputMaybe<Scalars['String']['input']>;
  /** Email of advisor assigned to customer */
  advisorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of advisor assigned to customer */
  advisorName?: InputMaybe<Scalars['String']['input']>;
  /** Any comments that might be relevant */
  comments?: InputMaybe<Scalars['String']['input']>;
  desiredProducts?: InputMaybe<Array<LeadProductTypeEnum>>;
  /** Customer email address */
  email: Scalars['String']['input'];
  /** Given first name(s) */
  firstName: Scalars['String']['input'];
  /** Legal surname */
  lastName: Scalars['String']['input'];
  /** Phone number including country code (+45) */
  phoneNumber: Scalars['String']['input'];
  /** Universally unique lead identifier */
  uuid: Scalars['String']['input'];
};

export type Battery = {
  brand: Scalars['String']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  capacity: Scalars['Float']['output'];
  customerEnabled?: Maybe<Scalars['Boolean']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  efficiency: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  size: HeatPumpSize;
  warrantyCycles?: Maybe<Scalars['Int']['output']>;
  warrantyYears?: Maybe<Scalars['Int']['output']>;
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
  weight: Scalars['Float']['output'];
};

export type BatteryInput = {
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  capacity: Scalars['Float']['input'];
  customerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  efficiency: Scalars['Float']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  warrantyCycles?: InputMaybe<Scalars['Int']['input']>;
  warrantyYears?: InputMaybe<Scalars['Int']['input']>;
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  weight: Scalars['Float']['input'];
};

export type BatteryProductRange = {
  batteries: Array<Battery>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type BatteryRangeInput = {
  batteryIDs: Array<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type BbrPrimaryHeating = {
  annualUsage: Scalars['Float']['output'];
  efficiency?: Maybe<Scalars['Float']['output']>;
  fuelSubtype?: Maybe<Scalars['String']['output']>;
  fuelType: Scalars['String']['output'];
  heatSource?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  volumeUnit?: Maybe<Scalars['String']['output']>;
};

export type BbrResidence = {
  area: Scalars['Float']['output'];
  areaHeated: Scalars['Float']['output'];
  areaNotHeated?: Maybe<Scalars['Int']['output']>;
  areaPartiallyHeated?: Maybe<Scalars['Float']['output']>;
  builtYear?: Maybe<Scalars['Float']['output']>;
  consumption?: Maybe<Consumption>;
  energyExpenditure: Scalars['Float']['output'];
  energyLabel?: Maybe<EnergyLabelEnum>;
  floors: Scalars['Float']['output'];
  heatDistribution?: Maybe<HeatDistributionTypeEnum>;
  id?: Maybe<Scalars['Int']['output']>;
  primaryHeating?: Maybe<BbrPrimaryHeating>;
  renovatedDegree?: Maybe<RenovationDegreeEnum>;
  renovatedYear?: Maybe<Scalars['Float']['output']>;
  residents?: Maybe<Scalars['Float']['output']>;
  roofs?: Maybe<Array<Roof>>;
  secondaryHeating?: Maybe<BbrSecondaryHeating>;
  type: ResidenceType;
  waterConsumption: WaterConsumptionDegreeEnum;
};

export type BbrSecondaryHeating = {
  heatSource?: Maybe<Scalars['String']['output']>;
};

export type BoxSize = {
  depth: Scalars['Float']['output'];
  height: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type BoxSizeInput = {
  depth: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type CrmCustomer = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type CrmCustomerFields = {
  address?: Maybe<Scalars['String']['output']>;
  assignedAdvisor?: Maybe<Scalars['String']['output']>;
  financialAdvisor?: Maybe<Scalars['String']['output']>;
  leadOrigin?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
};

export type CompositeFigure = {
  floor: Scalars['Float']['output'];
  radiator: Scalars['Float']['output'];
};

export type CompositeLabel = {
  floor: Scalars['String']['output'];
  radiator: Scalars['String']['output'];
};

export type Constant = {
  code: Scalars['String']['output'];
  denominator?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  numerator: Scalars['String']['output'];
  valueNumber: Scalars['Float']['output'];
};

export type ConstantInput = {
  code: Scalars['String']['input'];
  denominator?: InputMaybe<Scalars['String']['input']>;
  numerator: Scalars['String']['input'];
  valueNumber: Scalars['Float']['input'];
};

export type Consumption = {
  profileKey: ConsumptionProfile;
  profileValue: Array<Scalars['Float']['output']>;
};

export type ConsumptionProfile = 'homeAt17' | 'homeEveryDay' | 'homeInAfternoon' | 'homeInMorning' | 'homeMostDays';

export type Customer = {
  assignedAdvisor?: Maybe<User>;
  assignedAdvisorId?: Maybe<Scalars['Int']['output']>;
  callBackAt?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Float']['output'];
  eligibleForFinancing: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  financialAdvisor?: Maybe<Scalars['String']['output']>;
  financingProvider?: Maybe<CustomerFinancingProvider>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  includeHeatPumpSubsidy?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  leadOrigin?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  norlysPromotion?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  numberOfOffers?: Maybe<Scalars['Int']['output']>;
  ownerID?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Float']['output']>;
  usePriceInflation?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerFinancingProvider = 'jyskebank' | 'nordea';

export type CustomerInput = {
  assignedAdvisorId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  financialAdvisor?: InputMaybe<Scalars['String']['input']>;
  financingProvider?: InputMaybe<CustomerFinancingProvider>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  googleClickId?: InputMaybe<Scalars['String']['input']>;
  includeHeatPumpSubsidy?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  leadOrigin?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  norlysPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  usePriceInflation?: InputMaybe<Scalars['Boolean']['input']>;
  utmFields?: InputMaybe<UtmFields>;
};

export type CylinderSize = {
  diameter: Scalars['Float']['output'];
  height: Scalars['Float']['output'];
};

export type CylinderSizeInput = {
  diameter: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
};

export type DataConsentInput = {
  consented: Scalars['Boolean']['input'];
  partner: DataConsentPartner;
};

export type DataConsentPartner = 'edc' | 'jyskebank' | 'nordea' | 'norlys';

export type ElectricCar = {
  annualKilometersDriven: Scalars['Int']['output'];
  consumptionPerKilometer: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isSubsidised?: Maybe<Scalars['Boolean']['output']>;
  monthlySubscriptionPrice?: Maybe<Scalars['Float']['output']>;
  percentageCharged: Scalars['Int']['output'];
};

export type ElectricCarInput = {
  annualKilometersDriven: Scalars['Int']['input'];
  consumptionPerKilometer: Scalars['Int']['input'];
  isSubsidised?: InputMaybe<Scalars['Boolean']['input']>;
  monthlySubscriptionPrice?: InputMaybe<Scalars['Float']['input']>;
  percentageCharged: Scalars['Int']['input'];
};

export type EnergyLabelEnum = 'A2010' | 'A2015' | 'A2020' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type EnergyLabels = {
  before: Scalars['String']['output'];
  both?: Maybe<Scalars['String']['output']>;
  heatPump?: Maybe<Scalars['String']['output']>;
  solar?: Maybe<Scalars['String']['output']>;
};

export type FuelType = 'districtHeating' | 'electricity' | 'gas' | 'oil' | 'pallet' | 'straw' | 'wood';

export type HeatDistributionTypeEnum = 'floor' | 'radiator' | 'radiatorAndFloor';

export type HeatPumpApp = 'addition' | 'nibe' | 'unknown' | 'yes';

export type HeatPumpIndoorUnit = {
  app: HeatPumpApp;
  brand: Scalars['String']['output'];
  buffer: Scalars['Float']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  customerEnabled?: Maybe<Scalars['Boolean']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  internet: HeatPumpInternet;
  model: Scalars['String']['output'];
  noiseLevel: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  power?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  size: HeatPumpSize;
  solutionType: IndoorUnitSolutionType;
  waterVolume: Scalars['Float']['output'];
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type HeatPumpIndoorUnitInput = {
  app: HeatPumpApp;
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buffer: Scalars['Float']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  customerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  internet: HeatPumpInternet;
  model: Scalars['String']['input'];
  noiseLevel: Scalars['Float']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  power?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  solutionType?: InputMaybe<IndoorUnitSolutionType>;
  waterVolume: Scalars['Float']['input'];
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HeatPumpInternet = 'LAN' | 'unknown' | 'wifi' | 'wifiAddition';

export type HeatPumpOfferInput = {
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  expenses?: InputMaybe<Array<OfferExpenseInput>>;
  heatPumpIndoorProduct?: InputMaybe<ProductInput>;
  heatPumpOutdoorProduct?: InputMaybe<ProductInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reservations?: InputMaybe<Scalars['String']['input']>;
};

export type HeatPumpOutdoorUnit = {
  brand: Scalars['String']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  compatibleIndoorUnits: Array<Scalars['Int']['output']>;
  copHotWater?: Maybe<Scalars['Float']['output']>;
  customerEnabled?: Maybe<Scalars['Boolean']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  effect7: CompositeFigure;
  effect12: CompositeFigure;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  energyLabel: CompositeLabel;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  noiseLevel: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  scop: CompositeFigure;
  size: HeatPumpSize;
  temperatureOut: Scalars['Int']['output'];
  waterLabel: Scalars['String']['output'];
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type HeatPumpOutdoorUnitInput = {
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  compatibleIndoorUnits: Array<Scalars['Int']['input']>;
  copHotWater: Scalars['Float']['input'];
  customerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  effectFloor7: Scalars['Float']['input'];
  effectFloor12: Scalars['Float']['input'];
  effectRadiator7: Scalars['Float']['input'];
  effectRadiator12: Scalars['Float']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  energyLabelFloor: Scalars['String']['input'];
  energyLabelRadiator: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  noiseLevel: Scalars['Float']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  scopFloor: Scalars['Float']['input'];
  scopRadiator: Scalars['Float']['input'];
  temperatureOut: Scalars['Int']['input'];
  waterLabel: Scalars['String']['input'];
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HeatPumpPackageInput = {
  calculatorEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  indoorUnitID?: InputMaybe<Scalars['Int']['input']>;
  outdoorUnitID: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HeatPumpProductPackage = {
  calculatorEnabled?: Maybe<Scalars['Boolean']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  indoorUnits?: Maybe<Array<HeatPumpIndoorUnit>>;
  outdoorUnits: Array<HeatPumpOutdoorUnit>;
  packageID: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type HeatPumpSize = BoxSize | CylinderSize;

export type HeatingPriority = 'primary' | 'secondary';

export type HouseInput = {
  annualPrice: Scalars['Float']['input'];
  fuelType: FuelType;
};

export type HouseInputWithUsage = {
  annualUsage: Scalars['Float']['input'];
  electricCar?: InputMaybe<ElectricCarInput>;
  energyExpenditure?: InputMaybe<Scalars['Float']['input']>;
  fuelType: FuelType;
};

export type Image = {
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  residenceID?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type IndoorUnitSolutionType = 'mono' | 'split';

export type Inverter = {
  brand: Scalars['String']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  capacity: Scalars['Float']['output'];
  compatibleBatteries?: Maybe<Array<Scalars['Int']['output']>>;
  customerEnabled?: Maybe<Scalars['Boolean']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  efficiency: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  size: HeatPumpSize;
  warrantyYears: Scalars['Int']['output'];
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
  weight: Scalars['Float']['output'];
};

export type InverterInput = {
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  capacity: Scalars['Float']['input'];
  compatibleBatteries?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  efficiency: Scalars['Float']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  warrantyYears: Scalars['Int']['input'];
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  weight: Scalars['Float']['input'];
};

export type LeadProductTypeEnum = 'heatPump' | 'other' | 'solar';

export type LoginResult = {
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  addCustomerLead: Scalars['Boolean']['output'];
  archiveOffer: Scalars['Boolean']['output'];
  callMe: Scalars['Boolean']['output'];
  contactMe: Scalars['Boolean']['output'];
  copyOffer: Offer;
  createBatteryRange: BatteryProductRange;
  createConstant: Constant;
  createHeatPumpPackage: HeatPumpProductPackage;
  createProductDescription: ProductDescription;
  createSolarProductPackage?: Maybe<SolarProductPackage>;
  deleteAssortedProductDatasheet: Scalars['Boolean']['output'];
  deleteBatteryDatasheet: Scalars['Boolean']['output'];
  deleteBatteryRange: Scalars['Boolean']['output'];
  deleteElectricCar: Scalars['Boolean']['output'];
  deleteExpenseTemplate: Scalars['Boolean']['output'];
  deleteHeatPumpIndoorUnitDatasheet: Scalars['Boolean']['output'];
  deleteHeatPumpOutdoorUnitDatasheet: Scalars['Boolean']['output'];
  deleteHeatPumpPackage: Scalars['Boolean']['output'];
  deleteInverterDatasheet: Scalars['Boolean']['output'];
  deleteOfferExpenses: Scalars['Boolean']['output'];
  deleteProductDescription: Scalars['Boolean']['output'];
  deleteResidenceImage: Scalars['Boolean']['output'];
  deleteSiteVisitFile: Scalars['Boolean']['output'];
  deleteSolarPanelDatasheet: Scalars['Boolean']['output'];
  deleteSolarProductPackage: Scalars['Boolean']['output'];
  forgotPassword: Scalars['String']['output'];
  hardDeleteCustomer: Scalars['Boolean']['output'];
  insertAndSendOffers: Scalars['Boolean']['output'];
  insertAssortedProduct?: Maybe<AssortedProduct>;
  insertBattery?: Maybe<Battery>;
  insertHeatPumpIndoorUnit?: Maybe<HeatPumpIndoorUnit>;
  insertHeatPumpOutdoorUnit?: Maybe<HeatPumpOutdoorUnit>;
  insertInverter?: Maybe<Inverter>;
  insertOffers: Array<Offer>;
  insertSolarPanel?: Maybe<SolarPanel>;
  login: LoginResult;
  logout: Scalars['Boolean']['output'];
  markOfferAsSent: Scalars['Boolean']['output'];
  refreshLogin: LoginResult;
  regeneratePreview: Scalars['String']['output'];
  register: User;
  registerLead: Scalars['Boolean']['output'];
  removeConstant: Scalars['Boolean']['output'];
  resendVerificationEmail: Scalars['String']['output'];
  setOfferExpenses: Array<OfferExpense>;
  setResidenceRoofType: Scalars['Boolean']['output'];
  syncContactInformationWithCRM: Scalars['Boolean']['output'];
  syncOfferExpenses: Offer;
  syncOfferInformationWithCRM: Scalars['Boolean']['output'];
  syncProductsWithWebshop: Scalars['Boolean']['output'];
  unarchiveOffer: Scalars['Boolean']['output'];
  unregister: Scalars['Boolean']['output'];
  updateAssortedProduct?: Maybe<AssortedProduct>;
  updateAssortedProductImage: Scalars['String']['output'];
  updateBattery?: Maybe<Battery>;
  updateBatteryImage: Scalars['String']['output'];
  updateBatteryRange: BatteryProductRange;
  updateConstant: Constant;
  updateHeatPumpIndoorImage: Scalars['String']['output'];
  updateHeatPumpIndoorUnit?: Maybe<HeatPumpIndoorUnit>;
  updateHeatPumpOutdoorImage: Scalars['String']['output'];
  updateHeatPumpOutdoorUnit?: Maybe<HeatPumpOutdoorUnit>;
  updateHeatPumpPackage: HeatPumpProductPackage;
  updateInverter?: Maybe<Inverter>;
  updateInverterImage: Scalars['String']['output'];
  updateOfferNotes: Offer;
  updateOfferProductPrice: Offer;
  updatePassword: Scalars['Boolean']['output'];
  updateProductDescription: ProductDescription;
  updateSolarPanel?: Maybe<SolarPanel>;
  updateSolarPanelImage: Scalars['String']['output'];
  updateSolarProductPackage?: Maybe<SolarProductPackage>;
  updateUser?: Maybe<User>;
  uploadAssortedProductDatasheet: Scalars['Boolean']['output'];
  uploadBatteryDatasheet: Scalars['Boolean']['output'];
  uploadHeatPumpIndoorUnitDatasheet: Scalars['Boolean']['output'];
  uploadHeatPumpOutdoorUnitDatasheet: Scalars['Boolean']['output'];
  uploadInverterDatasheet: Scalars['Boolean']['output'];
  uploadPublicFile: Scalars['String']['output'];
  uploadResidenceImages: Array<Image>;
  uploadSiteVisitFile: Scalars['String']['output'];
  uploadSolarPanelDatasheet: Scalars['Boolean']['output'];
  upsertCustomer: Customer;
  upsertExpenseTemplate: OfferExpenseTemplate;
  upsertReservation: Offer;
  upsertResidence: Residence;
  upsertSiteVisitAnswerList: Scalars['Boolean']['output'];
};

export type MutationAddCustomerLeadArgs = {
  customer: BankCustomerLead;
};

export type MutationArchiveOfferArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCallMeArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  leadOrigin?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type MutationContactMeArgs = {
  email: Scalars['String']['input'];
  inquiry?: InputMaybe<Scalars['String']['input']>;
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCopyOfferArgs = {
  archiveExisting?: InputMaybe<Scalars['Boolean']['input']>;
  existingOfferID: Scalars['Int']['input'];
  offer: OfferInput;
};

export type MutationCreateBatteryRangeArgs = {
  batteryRangeInput: BatteryRangeInput;
};

export type MutationCreateConstantArgs = {
  constant: ConstantInput;
};

export type MutationCreateHeatPumpPackageArgs = {
  heatPumpPackageInput?: InputMaybe<HeatPumpPackageInput>;
};

export type MutationCreateProductDescriptionArgs = {
  description: ProductDescriptionInput;
};

export type MutationCreateSolarProductPackageArgs = {
  solarPackageInput?: InputMaybe<SolarProductPackageInput>;
};

export type MutationDeleteAssortedProductDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteBatteryDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteBatteryRangeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteElectricCarArgs = {
  residenceID: Scalars['Int']['input'];
};

export type MutationDeleteExpenseTemplateArgs = {
  templateID: Scalars['Int']['input'];
};

export type MutationDeleteHeatPumpIndoorUnitDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteHeatPumpOutdoorUnitDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteHeatPumpPackageArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteInverterDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteOfferExpensesArgs = {
  expenseIds: Array<Scalars['Int']['input']>;
  offerId: Scalars['Int']['input'];
};

export type MutationDeleteProductDescriptionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteResidenceImageArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  residenceID: Scalars['Int']['input'];
};

export type MutationDeleteSiteVisitFileArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type MutationDeleteSolarPanelDatasheetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteSolarProductPackageArgs = {
  id: Scalars['Int']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationHardDeleteCustomerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationInsertAndSendOffersArgs = {
  address: AddressInput;
  customer: CustomerInput;
  dataConsent?: InputMaybe<DataConsentInput>;
  heatPumpOffers?: InputMaybe<Array<HeatPumpOfferInput>>;
  residence: ResidenceInput;
  solarOffers?: InputMaybe<Array<SolarOfferInput>>;
};

export type MutationInsertAssortedProductArgs = {
  unit: AssortedProductInput;
};

export type MutationInsertBatteryArgs = {
  unit: BatteryInput;
};

export type MutationInsertHeatPumpIndoorUnitArgs = {
  unit: HeatPumpIndoorUnitInput;
};

export type MutationInsertHeatPumpOutdoorUnitArgs = {
  unit: HeatPumpOutdoorUnitInput;
};

export type MutationInsertInverterArgs = {
  unit: InverterInput;
};

export type MutationInsertOffersArgs = {
  address?: InputMaybe<AddressInput>;
  assortedOffers?: InputMaybe<Array<AssortedOfferInput>>;
  customerID?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  heatPumpOffers?: InputMaybe<Array<HeatPumpOfferInput>>;
  residenceID?: InputMaybe<Scalars['Int']['input']>;
  solarOffers?: InputMaybe<Array<SolarOfferInput>>;
};

export type MutationInsertSolarPanelArgs = {
  unit: SolarPanelInput;
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationMarkOfferAsSentArgs = {
  offerID: Scalars['Int']['input'];
  type: OfferPdfTypeEnum;
};

export type MutationRefreshLoginArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRegeneratePreviewArgs = {
  offerID: Scalars['Int']['input'];
  type: OfferPdfTypeEnum;
};

export type MutationRegisterArgs = {
  user: RegisterUserInput;
};

export type MutationRegisterLeadArgs = {
  address: AddressInput;
  customer: CustomerInput;
};

export type MutationRemoveConstantArgs = {
  code: Scalars['String']['input'];
};

export type MutationSetOfferExpensesArgs = {
  expenses: Array<OfferExpenseInput>;
  offerID: Scalars['Int']['input'];
};

export type MutationSetResidenceRoofTypeArgs = {
  residenceId: Scalars['Int']['input'];
  roofType: RoofType;
};

export type MutationSyncContactInformationWithCrmArgs = {
  residenceId: Scalars['Int']['input'];
};

export type MutationSyncOfferExpensesArgs = {
  offerID: Scalars['Int']['input'];
};

export type MutationSyncOfferInformationWithCrmArgs = {
  offerId: Scalars['Int']['input'];
};

export type MutationUnarchiveOfferArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUnregisterArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateAssortedProductArgs = {
  id: Scalars['Int']['input'];
  unit: AssortedProductInput;
};

export type MutationUpdateAssortedProductImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateBatteryArgs = {
  id: Scalars['Int']['input'];
  unit: BatteryInput;
};

export type MutationUpdateBatteryImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateBatteryRangeArgs = {
  batteryRangeInput: BatteryRangeInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateConstantArgs = {
  constant: ConstantInput;
};

export type MutationUpdateHeatPumpIndoorImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateHeatPumpIndoorUnitArgs = {
  id: Scalars['Int']['input'];
  unit: HeatPumpIndoorUnitInput;
};

export type MutationUpdateHeatPumpOutdoorImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateHeatPumpOutdoorUnitArgs = {
  id: Scalars['Int']['input'];
  unit: HeatPumpOutdoorUnitInput;
};

export type MutationUpdateHeatPumpPackageArgs = {
  heatPumpPackageInput: HeatPumpPackageInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateInverterArgs = {
  id: Scalars['Int']['input'];
  unit: InverterInput;
};

export type MutationUpdateInverterImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateOfferNotesArgs = {
  notes: Scalars['String']['input'];
  offerID: Scalars['Int']['input'];
};

export type MutationUpdateOfferProductPriceArgs = {
  offerID: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  type: OfferProductType;
  unitID: Scalars['Int']['input'];
};

export type MutationUpdatePasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdateProductDescriptionArgs = {
  description: ProductDescriptionInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateSolarPanelArgs = {
  id: Scalars['Int']['input'];
  unit: SolarPanelInput;
};

export type MutationUpdateSolarPanelImageArgs = {
  id: Scalars['Int']['input'];
  image: Scalars['Upload']['input'];
};

export type MutationUpdateSolarProductPackageArgs = {
  id: Scalars['Int']['input'];
  solarPackageInput: SolarProductPackageInput;
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type MutationUploadAssortedProductDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUploadBatteryDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUploadHeatPumpIndoorUnitDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUploadHeatPumpOutdoorUnitDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUploadInverterDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUploadPublicFileArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationUploadResidenceImagesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  images: Array<Scalars['Upload']['input']>;
};

export type MutationUploadSiteVisitFileArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  offerID: Scalars['Int']['input'];
};

export type MutationUploadSolarPanelDatasheetArgs = {
  datasheet: Scalars['Upload']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUpsertCustomerArgs = {
  customer: CustomerInput;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpsertExpenseTemplateArgs = {
  template: OfferExpenseTemplateInput;
};

export type MutationUpsertReservationArgs = {
  offerID: Scalars['Int']['input'];
  reservation: Scalars['String']['input'];
};

export type MutationUpsertResidenceArgs = {
  customerID?: InputMaybe<Scalars['Int']['input']>;
  customerInput?: InputMaybe<CustomerInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  residence: ResidenceInput;
};

export type MutationUpsertSiteVisitAnswerListArgs = {
  answerList: Array<SiteVisitAnswerInput>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type NewOfferExpenses = {
  heatPump: Array<OfferExpenseTemplate>;
  required: Array<OfferExpenseTemplate>;
  solar: Array<OfferExpenseTemplate>;
};

export type Offer = {
  address?: Maybe<Address>;
  assortedProducts?: Maybe<Array<AssortedProduct>>;
  battery?: Maybe<Battery>;
  batteryProduct?: Maybe<Product>;
  chosenHeating?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Float']['output']>;
  expenses?: Maybe<Array<OfferExpense>>;
  finalPreview: Scalars['String']['output'];
  heatPumpIndoorProduct?: Maybe<Product>;
  heatPumpIndoorUnit?: Maybe<HeatPumpIndoorUnit>;
  heatPumpOutdoorProduct?: Maybe<Product>;
  heatPumpOutdoorUnit?: Maybe<HeatPumpOutdoorUnit>;
  id: Scalars['Int']['output'];
  installationAccessCode: Scalars['String']['output'];
  inverter?: Maybe<Inverter>;
  inverterProduct?: Maybe<Product>;
  notes?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  preliminaryPreview: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  reservations?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Residence>;
  residenceID: Scalars['Int']['output'];
  sentOffers?: Maybe<Array<OfferDispatch>>;
  siteVisitAccessCode: Scalars['String']['output'];
  solarPanel?: Maybe<SolarPanel>;
  solarPanelProduct?: Maybe<Product>;
  tag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type OfferActor = 'advisor' | 'customer';

export type OfferDispatch = {
  id: Scalars['Int']['output'];
  sentAt?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<OfferPdfTypeEnum>;
};

export type OfferExpense = {
  buyPrice?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  offerID: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  templateID?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<OfferExpenseType>;
};

export type OfferExpenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  templateID?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OfferExpenseType>;
};

export type OfferExpenseTemplate = {
  buyPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Float']['output'];
  denominator?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  includedByDefault?: Maybe<Scalars['Boolean']['output']>;
  minimumProducts?: Maybe<Scalars['Int']['output']>;
  offerType?: Maybe<OfferType>;
  price?: Maybe<Scalars['Float']['output']>;
  specialKey?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type OfferExpenseTemplateInput = {
  buyPrice: Scalars['Float']['input'];
  denominator?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offerType: OfferType;
  price: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type OfferExpenseType = 'included' | 'installation' | 'required';

export type OfferInput = {
  assortedProducts?: InputMaybe<Array<ProductInput>>;
  batteryProduct?: InputMaybe<ProductInput>;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  expenses?: InputMaybe<Array<OfferExpenseInput>>;
  heatPumpIndoorProduct?: InputMaybe<ProductInput>;
  heatPumpOutdoorProduct?: InputMaybe<ProductInput>;
  inverterProduct?: InputMaybe<ProductInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reservations?: InputMaybe<Scalars['String']['input']>;
  solarPanelProduct?: InputMaybe<ProductInput>;
};

export type OfferPdfTypeEnum = 'final' | 'preliminary';

export type OfferProductType =
  | 'assorted'
  | 'battery'
  | 'heatPumpIndoorUnit'
  | 'heatPumpOutdoorUnit'
  | 'inverter'
  | 'solarPanel';

export type OfferProducts = {
  battery?: Maybe<Battery>;
  expenses?: Maybe<Array<OfferExpense>>;
  heatPumpIndoorUnit?: Maybe<HeatPumpIndoorUnit>;
  heatPumpOutdoorUnit?: Maybe<HeatPumpOutdoorUnit>;
  inverter?: Maybe<Inverter>;
  price?: Maybe<Scalars['Float']['output']>;
  solarPackageDiscount?: Maybe<Scalars['Int']['output']>;
  solarPackageId?: Maybe<Scalars['Int']['output']>;
  solarPanel?: Maybe<SolarPanel>;
};

export type OfferSavings = {
  after: ProductUsage;
  before: ProductUsage;
  saved: OfferTotalSaved;
};

export type OfferSavingsWithProducts = {
  disclaimer: Scalars['String']['output'];
  investment: Scalars['Float']['output'];
  paybackMonths: Scalars['Float']['output'];
  products: OfferProducts;
  residence?: Maybe<SimpleResidenceResult>;
  savings: OfferSavings;
  types: Array<OfferType>;
};

export type OfferTotalSaved = {
  emission: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  priceByYear: Array<Scalars['Float']['output']>;
};

export type OfferType = 'assorted' | 'heatPump' | 'solar';

export type OffersWithCount = {
  count: Scalars['Int']['output'];
  offers: Array<Offer>;
};

export type PanelCategory = 'builtIn' | 'freeStanding';

export type PanelType = 'monoCrystal' | 'polyCrystal';

export type PartialResidenceInput = {
  addressID?: InputMaybe<Scalars['Float']['input']>;
  area?: InputMaybe<Scalars['Float']['input']>;
  areaHeated?: InputMaybe<Scalars['Float']['input']>;
  areaNotHeated?: InputMaybe<Scalars['Float']['input']>;
  areaPartiallyHeated?: InputMaybe<Scalars['Float']['input']>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  electricCar?: InputMaybe<ElectricCarInput>;
  energyExpenditure?: InputMaybe<Scalars['Float']['input']>;
  energyLabel?: InputMaybe<EnergyLabelEnum>;
  heatDistribution?: InputMaybe<HeatDistributionTypeEnum>;
  primaryHeating?: InputMaybe<ResidenceHeatingInput>;
  radiatorFloorPercentage?: InputMaybe<Scalars['Float']['input']>;
  roofs?: InputMaybe<Array<RoofInput>>;
  secondaryHeating?: InputMaybe<ResidenceHeatingInput>;
  type?: InputMaybe<ResidenceType>;
};

export type Product = {
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type ProductDescription = {
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: OfferProductType;
};

export type ProductDescriptionInput = {
  content: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: OfferProductType;
};

export type ProductInput = {
  id: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductUsage = {
  bought: Scalars['Float']['output'];
  boughtPrice?: Maybe<Scalars['Float']['output']>;
  consumptionPerMonth?: Maybe<Array<Scalars['Float']['output']>>;
  domesticDiscount: Scalars['Float']['output'];
  domesticPrice: Scalars['Float']['output'];
  electricityDiscount?: Maybe<Scalars['Float']['output']>;
  fuelPrice?: Maybe<Scalars['Float']['output']>;
  netEarning?: Maybe<Scalars['Float']['output']>;
  netSpentElectricity?: Maybe<Scalars['Float']['output']>;
  produced: Scalars['Float']['output'];
  productionPerMonth?: Maybe<Array<Scalars['Float']['output']>>;
  sold: Scalars['Float']['output'];
  soldPrice?: Maybe<Scalars['Float']['output']>;
  totalEmission?: Maybe<Scalars['Float']['output']>;
  totalPrice: Scalars['Float']['output'];
  used: Scalars['Float']['output'];
  usedPercentage?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  addressSavings: AllOfferSavings;
  allOfferTypeSavings: Array<OfferSavingsWithProducts>;
  assortedProduct?: Maybe<AssortedProduct>;
  assortedProductBrands: Array<Scalars['String']['output']>;
  assortedProducts: Array<AssortedProduct>;
  batteries: Array<Battery>;
  battery?: Maybe<Battery>;
  batteryBrands: Array<Scalars['String']['output']>;
  batteryRanges: Array<BatteryProductRange>;
  bbrLookupByAddress?: Maybe<BbrResidence>;
  bbrLookupByDawaId?: Maybe<BbrResidence>;
  bodilAdvisors: Array<User>;
  calculateAddressSavings: AllOfferSavings;
  calculateEnergyLabels: EnergyLabels;
  calculateOfferSavings: OfferSavings;
  calculateOfferTypeSavings: OfferSavingsWithProducts;
  constants: Array<Constant>;
  constantsWithPartialCode: Array<Constant>;
  crmCustomerFieldValues?: Maybe<CrmCustomerFields>;
  customer?: Maybe<Customer>;
  dashboardOffers: OffersWithCount;
  energyLabel?: Maybe<EnergyLabelEnum>;
  energyOptions: Array<Constant>;
  expenseTemplates: Array<OfferExpenseTemplate>;
  heatPumpBrands: Array<Scalars['String']['output']>;
  heatPumpIndoorUnit?: Maybe<HeatPumpIndoorUnit>;
  heatPumpIndoorUnits: Array<HeatPumpIndoorUnit>;
  heatPumpOutdoorUnit?: Maybe<HeatPumpOutdoorUnit>;
  heatPumpOutdoorUnits: Array<HeatPumpOutdoorUnit>;
  heatPumpPackages: Array<HeatPumpProductPackage>;
  inverter?: Maybe<Inverter>;
  inverterBrands: Array<Scalars['String']['output']>;
  inverters: Array<Inverter>;
  me: User;
  newOfferExpenses: NewOfferExpenses;
  offer?: Maybe<Offer>;
  offerByCode?: Maybe<Offer>;
  offerByTag?: Maybe<Offer>;
  offerSavings: OfferSavings;
  offerTypeSavings: OfferSavingsWithProducts;
  offers?: Maybe<Array<Offer>>;
  productDescriptions: Array<ProductDescription>;
  recommendedSolarPackage: SolarProductPackage;
  residence?: Maybe<Residence>;
  residences?: Maybe<Array<Residence>>;
  residencesBySearch: Array<Residence>;
  searchCRMCustomers?: Maybe<Array<CrmCustomer>>;
  searchCustomers?: Maybe<Array<Customer>>;
  searchCustomersByEmail?: Maybe<Array<Customer>>;
  siteVisitAnswerList: Array<SiteVisitAnswer>;
  siteVisitFiles: Array<SiteVisitFile>;
  solarPackageSavings: Array<SolarPackageSavings>;
  solarPanel?: Maybe<SolarPanel>;
  solarPanelBrands: Array<Scalars['String']['output']>;
  solarPanels: Array<SolarPanel>;
  solarProductPackages: Array<SolarProductPackage>;
  user?: Maybe<User>;
};

export type QueryAddressSavingsArgs = {
  dawaId: Scalars['String']['input'];
  isAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<HouseInputWithUsage>;
};

export type QueryAllOfferTypeSavingsArgs = {
  address: AddressInput;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<Array<Scalars['Float']['input']>>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  isAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<PartialResidenceInput>;
  types: Array<OfferType>;
};

export type QueryAssortedProductArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAssortedProductsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryBatteriesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBatteryArgs = {
  id: Scalars['Int']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBatteryRangesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryBbrLookupByAddressArgs = {
  address: AddressInput;
};

export type QueryBbrLookupByDawaIdArgs = {
  dawaId: Scalars['String']['input'];
};

export type QueryCalculateAddressSavingsArgs = {
  dawaId: Scalars['String']['input'];
  residence?: InputMaybe<HouseInput>;
};

export type QueryCalculateEnergyLabelsArgs = {
  address: AddressInput;
  residence?: InputMaybe<PartialResidenceInput>;
};

export type QueryCalculateOfferSavingsArgs = {
  address: AddressInput;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<Array<Scalars['Float']['input']>>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  offer: OfferInput;
  residence?: InputMaybe<PartialResidenceInput>;
};

export type QueryCalculateOfferTypeSavingsArgs = {
  address: AddressInput;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<Array<Scalars['Float']['input']>>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  residence?: InputMaybe<PartialResidenceInput>;
  types: Array<OfferType>;
};

export type QueryConstantsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type QueryConstantsWithPartialCodeArgs = {
  partialCode: Scalars['String']['input'];
};

export type QueryCrmCustomerFieldValuesArgs = {
  id: Scalars['String']['input'];
};

export type QueryCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDashboardOffersArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offerType?: InputMaybe<OfferType>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<SortOrder>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEnergyLabelArgs = {
  addressId: Scalars['String']['input'];
};

export type QueryEnergyOptionsArgs = {
  residenceId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExpenseTemplatesArgs = {
  type: OfferType;
};

export type QueryHeatPumpIndoorUnitArgs = {
  id: Scalars['Int']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHeatPumpIndoorUnitsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHeatPumpOutdoorUnitArgs = {
  id: Scalars['Int']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHeatPumpOutdoorUnitsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHeatPumpPackagesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryInverterArgs = {
  id: Scalars['Int']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryInvertersArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOfferArgs = {
  id: Scalars['Int']['input'];
};

export type QueryOfferByCodeArgs = {
  code: Scalars['String']['input'];
  purpose: TokenPurpose;
};

export type QueryOfferByTagArgs = {
  offerTag: Scalars['String']['input'];
};

export type QueryOfferSavingsArgs = {
  address?: InputMaybe<AddressInput>;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<Array<Scalars['Float']['input']>>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  offer: OfferInput;
  residence: ResidenceInput;
};

export type QueryOfferTypeSavingsArgs = {
  address: AddressInput;
  chosenHeating?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<Array<Scalars['Float']['input']>>;
  customerID?: InputMaybe<Scalars['Float']['input']>;
  isAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<PartialResidenceInput>;
  types: Array<OfferType>;
};

export type QueryOffersArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  customerID?: InputMaybe<Scalars['Int']['input']>;
  residenceID?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProductDescriptionsArgs = {
  type: OfferProductType;
};

export type QueryRecommendedSolarPackageArgs = {
  residence: ResidenceInput;
};

export type QueryResidenceArgs = {
  address?: InputMaybe<AddressInput>;
  customerID?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryResidencesArgs = {
  address?: InputMaybe<AddressInput>;
  customerID?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryResidencesBySearchArgs = {
  actor?: InputMaybe<OfferActor>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchCrmCustomersArgs = {
  email: Scalars['String']['input'];
};

export type QuerySearchCustomersArgs = {
  search: Scalars['String']['input'];
};

export type QuerySearchCustomersByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QuerySiteVisitAnswerListArgs = {
  residenceID: Scalars['Int']['input'];
};

export type QuerySiteVisitFilesArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  offerID: Scalars['Int']['input'];
};

export type QuerySolarPackageSavingsArgs = {
  residence: ResidenceInput;
};

export type QuerySolarPanelArgs = {
  id: Scalars['Int']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySolarPanelsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySolarProductPackagesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  onlyDefaultBattery?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};

/** register user */
export type RegisterUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RenovationDegreeEnum = 'asBuilt' | 'maximum' | 'medium';

export type Residence = {
  address?: Maybe<Address>;
  addressID: Scalars['Int']['output'];
  area: Scalars['Float']['output'];
  areaHeated: Scalars['Float']['output'];
  areaNotHeated?: Maybe<Scalars['Int']['output']>;
  areaPartiallyHeated?: Maybe<Scalars['Float']['output']>;
  builtYear?: Maybe<Scalars['Float']['output']>;
  consumption?: Maybe<Consumption>;
  consumptionProfile?: Maybe<ConsumptionProfile>;
  createdAt: Scalars['Float']['output'];
  customer?: Maybe<Customer>;
  customerID: Scalars['Int']['output'];
  electricCar?: Maybe<ElectricCar>;
  electricityPrice?: Maybe<Scalars['Float']['output']>;
  energyExpenditure: Scalars['Float']['output'];
  energyLabel?: Maybe<EnergyLabelEnum>;
  floors: Scalars['Float']['output'];
  heatDistribution: HeatDistributionTypeEnum;
  heatingCircuits?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Image>;
  noiseWeight: WaterConsumptionDegreeEnum;
  offerProducts?: Maybe<Array<OfferType>>;
  primaryHeating?: Maybe<ResidenceHeating>;
  radiatorFloorPercentage?: Maybe<Scalars['Float']['output']>;
  renovatedDegree?: Maybe<RenovationDegreeEnum>;
  renovatedYear?: Maybe<Scalars['Float']['output']>;
  residents?: Maybe<Scalars['Float']['output']>;
  roofType?: Maybe<RoofType>;
  roofs?: Maybe<Array<Roof>>;
  secondaryHeating?: Maybe<ResidenceHeating>;
  type: ResidenceType;
  updatedAt?: Maybe<Scalars['Float']['output']>;
  waterConsumption: WaterConsumptionDegreeEnum;
};

export type ResidenceHeating = {
  annualUsage: Scalars['Float']['output'];
  efficiency?: Maybe<Scalars['Float']['output']>;
  fuelSubtype?: Maybe<Scalars['String']['output']>;
  fuelType: Scalars['String']['output'];
  heatSource?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  priority: HeatingPriority;
  volumeUnit?: Maybe<Scalars['String']['output']>;
};

export type ResidenceHeatingInput = {
  annualUsage: Scalars['Float']['input'];
  efficiency?: InputMaybe<Scalars['Float']['input']>;
  fuelSubtype?: InputMaybe<Scalars['String']['input']>;
  fuelType: Scalars['String']['input'];
  heatSource?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priority?: InputMaybe<HeatingPriority>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
};

export type ResidenceInput = {
  address?: InputMaybe<AddressInput>;
  addressID?: InputMaybe<Scalars['Int']['input']>;
  area: Scalars['Float']['input'];
  areaHeated: Scalars['Float']['input'];
  areaNotHeated?: InputMaybe<Scalars['Int']['input']>;
  areaPartiallyHeated?: InputMaybe<Scalars['Float']['input']>;
  builtYear?: InputMaybe<Scalars['Float']['input']>;
  consumptionProfile?: InputMaybe<ConsumptionProfile>;
  customerID?: InputMaybe<Scalars['Int']['input']>;
  electricCar?: InputMaybe<ElectricCarInput>;
  electricityPrice?: InputMaybe<Scalars['Float']['input']>;
  energyExpenditure: Scalars['Float']['input'];
  energyLabel?: InputMaybe<EnergyLabelEnum>;
  floors: Scalars['Float']['input'];
  heatDistribution: HeatDistributionTypeEnum;
  heatingCircuits?: InputMaybe<Scalars['Int']['input']>;
  noiseWeight?: InputMaybe<WaterConsumptionDegreeEnum>;
  primaryHeating: ResidenceHeatingInput;
  radiatorFloorPercentage?: InputMaybe<Scalars['Float']['input']>;
  renovatedDegree?: InputMaybe<RenovationDegreeEnum>;
  renovatedYear?: InputMaybe<Scalars['Float']['input']>;
  residents?: InputMaybe<Scalars['Float']['input']>;
  roofType?: InputMaybe<RoofType>;
  roofs?: InputMaybe<Array<RoofInput>>;
  secondaryHeating?: InputMaybe<ResidenceHeatingInput>;
  type: ResidenceType;
  waterConsumption: WaterConsumptionDegreeEnum;
};

export type ResidenceType = 'apartment' | 'summerhouse' | 'townhouse' | 'villa';

export type Roof = {
  height?: Maybe<Scalars['Float']['output']>;
  isGuessed?: Maybe<Scalars['Boolean']['output']>;
  maxPanelCoverage?: Maybe<Scalars['Float']['output']>;
  orientation: Scalars['Float']['output'];
  planeDepth: Scalars['Float']['output'];
  planeWidth: Scalars['Float']['output'];
  shadows?: Maybe<RoofShadows>;
};

export type RoofInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  isGuessed?: InputMaybe<Scalars['Boolean']['input']>;
  maxPanelCoverage?: InputMaybe<Scalars['Float']['input']>;
  orientation: Scalars['Int']['input'];
  planeDepth: Scalars['Int']['input'];
  planeWidth: Scalars['Int']['input'];
  shadows?: InputMaybe<RoofShadows>;
};

export type RoofShadows = 'many' | 'none' | 'some';

export type RoofType = 'clayTile' | 'concreteTile' | 'felt' | 'fiberCement' | 'flat' | 'metal' | 'other' | 'unknown';

export type SimpleResidenceResult = {
  annualElectricityPrice: Scalars['Float']['output'];
  annualHeatingPrice: Scalars['Float']['output'];
  existingSolarArrayEffect?: Maybe<Scalars['Float']['output']>;
  fuelType: FuelType;
};

export type SiteVisitAnswer = {
  answer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<SiteVisitQuestion>;
  residenceId: Scalars['Int']['output'];
};

export type SiteVisitAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question: SiteVisitQuestion;
  residenceId: Scalars['Int']['input'];
};

export type SiteVisitFile = {
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  offerID: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};

export type SiteVisitQuestion =
  | 'atticOrRaisedCelling'
  | 'bufferRequired'
  | 'bufferRequiredSolar'
  | 'bypassValves'
  | 'canFurnaceBeDismantled'
  | 'changeCirculationPump'
  | 'condensationPump'
  | 'correctColdRooms'
  | 'correctConsumption'
  | 'correctConsumptionSecondary'
  | 'correctHeatedArea'
  | 'digDownPipes'
  | 'distanceInOut'
  | 'distanceToInstallation'
  | 'distanceToSolar'
  | 'easyAvailableForScaffolding'
  | 'electricRelayAutoSafetyAndExtra'
  | 'electricRelayWork'
  | 'electricityNotes'
  | 'electricitySolarNotes'
  | 'floorHeatingExtraShunts'
  | 'floorPlanNotes'
  | 'hasUnderRoof'
  | 'heatPumpEntryPossible'
  | 'heatSourceNotes'
  | 'indoorAlternativeNotes'
  | 'indoorPlacementNotes'
  | 'indoorUnitOnFloorPlan'
  | 'installationOnFloorPlan'
  | 'inverterAndBatteryOnFloorPlan'
  | 'inverterAndBatteryPlacementNotes'
  | 'noiseLimitObserved'
  | 'otherNotes'
  | 'outdoorPlacementNotes'
  | 'outdoorUnitOnFloorPlan'
  | 'pipingMaterial'
  | 'pipingNotes'
  | 'pipingOnFloorPlan'
  | 'pipingPlacement'
  | 'providerForFoundation'
  | 'providerForFoundationSolar'
  | 'radiatorPipingSize'
  | 'radiatorsNotes'
  | 'replaceFurnaceWithRadiator'
  | 'replaceRadiators'
  | 'roofConstructionNotes'
  | 'roofType'
  | 'roomForMaterials'
  | 'roomForWaterTank'
  | 'solarCableOnFloorPlan'
  | 'solarEntryPossible'
  | 'solarOnFloorPlan'
  | 'solarPlacementNotes'
  | 'systemStringNumber'
  | 'twoPipingSystems'
  | 'wiringInCeiling'
  | 'wiringInCeilingForSolar';

export type SolarOfferInput = {
  batteryProduct?: InputMaybe<ProductInput>;
  expenses?: InputMaybe<Array<OfferExpenseInput>>;
  inverterProduct?: InputMaybe<ProductInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reservations?: InputMaybe<Scalars['String']['input']>;
  solarPanelProduct?: InputMaybe<ProductInput>;
};

export type SolarPackageSavings = {
  packageID: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  savings: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalPrice: Scalars['Float']['output'];
  totalSaved: Scalars['Float']['output'];
  usedPercentage: Scalars['Float']['output'];
};

export type SolarPanel = {
  annualProduction: Scalars['Float']['output'];
  brand: Scalars['String']['output'];
  buyPrice?: Maybe<Scalars['Float']['output']>;
  capacity: Scalars['Float']['output'];
  category: PanelCategory;
  compatibleInverters: Array<Scalars['Int']['output']>;
  customerEnabled?: Maybe<Scalars['Boolean']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  degradationPerYear: Scalars['Float']['output'];
  deletedAt?: Maybe<Scalars['Float']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  performanceWarrantyYears: Scalars['Int']['output'];
  preferred?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  productWarrantyYears: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  size: BoxSize;
  type: PanelType;
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
  weight: Scalars['Float']['output'];
};

export type SolarPanelInput = {
  annualProduction: Scalars['Float']['input'];
  boxSize?: InputMaybe<BoxSizeInput>;
  brand: Scalars['String']['input'];
  buyPrice?: InputMaybe<Scalars['Float']['input']>;
  capacity: Scalars['Float']['input'];
  category: PanelCategory;
  compatibleInverters: Array<Scalars['Int']['input']>;
  customerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cylinderSize?: InputMaybe<CylinderSizeInput>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  degradationPerYear: Scalars['Float']['input'];
  deletedAt?: InputMaybe<Scalars['Float']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  performanceWarrantyYears: Scalars['Int']['input'];
  preferred?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  productWarrantyYears: Scalars['Int']['input'];
  type: PanelType;
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  weight: Scalars['Float']['input'];
};

export type SolarProductPackage = {
  batteries?: Maybe<Array<Battery>>;
  batteryRangeID?: Maybe<Scalars['Int']['output']>;
  calculatorEnabled?: Maybe<Scalars['Boolean']['output']>;
  defaultBatteryID?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  inverter: Inverter;
  maximumEnergyExpenditure?: Maybe<Scalars['Float']['output']>;
  packageID: Scalars['Int']['output'];
  solarPanel: SolarPanel;
  title?: Maybe<Scalars['String']['output']>;
  webshopEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type SolarProductPackageInput = {
  batteryRangeID?: InputMaybe<Scalars['Int']['input']>;
  calculatorEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultBatteryID?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  inverterID: Scalars['Int']['input'];
  solarPanelID: Scalars['Int']['input'];
  solarPanelQuantity: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  webshopEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SortOrder = 'ascending' | 'descending';

export type TokenPurpose = 'forgotPassword' | 'installationAccess' | 'refreshToken' | 'siteVisitAccess' | 'verifyEmail';

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  authProviders?: Maybe<Array<AuthProvider>>;
  createdAt: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isMe: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profileImageID?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type UtmFields = {
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type WaterConsumptionDegreeEnum = 'large' | 'medium' | 'small';

export type BbrLookupByAddressQueryVariables = Exact<{
  address: AddressInput;
}>;

export type BbrLookupByAddressQuery = {
  bbrLookupByAddress?: {
    type: ResidenceType;
    floors: number;
    residents?: number | null;
    builtYear?: number | null;
    renovatedYear?: number | null;
    renovatedDegree?: RenovationDegreeEnum | null;
    area: number;
    areaHeated: number;
    areaNotHeated?: number | null;
    energyExpenditure: number;
    heatDistribution?: HeatDistributionTypeEnum | null;
    waterConsumption: WaterConsumptionDegreeEnum;
    primaryHeating?: {
      heatSource?: string | null;
      annualUsage: number;
      price?: number | null;
      efficiency?: number | null;
      fuelType: string;
      fuelSubtype?: string | null;
      volumeUnit?: string | null;
    } | null;
    secondaryHeating?: { heatSource?: string | null } | null;
    roofs?: Array<{
      orientation: number;
      planeWidth: number;
      planeDepth: number;
      height?: number | null;
      maxPanelCoverage?: number | null;
      shadows?: RoofShadows | null;
      isGuessed?: boolean | null;
    }> | null;
  } | null;
};

export type EnergyOptionsQueryVariables = Exact<{
  residenceId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EnergyOptionsQuery = {
  energyOptions: Array<{ code: string; denominator?: string | null; numerator: string; valueNumber: number }>;
};

export type ProductUsageFragment = {
  produced: number;
  bought: number;
  sold: number;
  used: number;
  usedPercentage?: number | null;
  consumptionPerMonth?: Array<number> | null;
  productionPerMonth?: Array<number> | null;
  totalPrice: number;
  domesticPrice: number;
  fuelPrice?: number | null;
  boughtPrice?: number | null;
  netEarning?: number | null;
  soldPrice?: number | null;
  domesticDiscount: number;
  electricityDiscount?: number | null;
  totalEmission?: number | null;
};

export type OfferTypeSavingsFragment = {
  types: Array<OfferType>;
  paybackMonths: number;
  savings: {
    after: {
      produced: number;
      bought: number;
      sold: number;
      used: number;
      usedPercentage?: number | null;
      consumptionPerMonth?: Array<number> | null;
      productionPerMonth?: Array<number> | null;
      totalPrice: number;
      domesticPrice: number;
      fuelPrice?: number | null;
      boughtPrice?: number | null;
      netEarning?: number | null;
      soldPrice?: number | null;
      domesticDiscount: number;
      electricityDiscount?: number | null;
      totalEmission?: number | null;
    };
    before: {
      produced: number;
      bought: number;
      sold: number;
      used: number;
      usedPercentage?: number | null;
      consumptionPerMonth?: Array<number> | null;
      productionPerMonth?: Array<number> | null;
      totalPrice: number;
      domesticPrice: number;
      fuelPrice?: number | null;
      boughtPrice?: number | null;
      netEarning?: number | null;
      soldPrice?: number | null;
      domesticDiscount: number;
      electricityDiscount?: number | null;
      totalEmission?: number | null;
    };
    saved: { price: number; emission: number };
  };
  products: {
    price?: number | null;
    heatPumpOutdoorUnit?: { id: number; price: number; brand: string; model: string; imageUrl?: string | null } | null;
    solarPanel?: {
      id: number;
      price: number;
      brand: string;
      model: string;
      imageUrl?: string | null;
      quantity?: number | null;
    } | null;
    inverter?: { id: number; price: number } | null;
    battery?: { id: number; price: number } | null;
    heatPumpIndoorUnit?: { id: number; price: number } | null;
  };
};

export type InsertAndSendOffersMutationVariables = Exact<{
  heatPumpOffers?: InputMaybe<Array<HeatPumpOfferInput>>;
  solarOffers?: InputMaybe<Array<SolarOfferInput>>;
  customer: CustomerInput;
  residence: ResidenceInput;
  address: AddressInput;
  dataConsent?: InputMaybe<DataConsentInput>;
}>;

export type InsertAndSendOffersMutation = { insertAndSendOffers: boolean };

export type AddressSavingsQueryVariables = Exact<{
  dawaId: Scalars['String']['input'];
  residence?: InputMaybe<HouseInputWithUsage>;
}>;

export type AddressSavingsQuery = {
  addressSavings: {
    results: Array<{
      types: Array<OfferType>;
      paybackMonths: number;
      savings: { saved: { price: number; emission: number; priceByYear: Array<number> } };
      products: {
        price?: number | null;
        heatPumpIndoorUnit?: {
          id: number;
          model: string;
          brand: string;
          imageUrl?: string | null;
          price: number;
        } | null;
        heatPumpOutdoorUnit?: {
          id: number;
          model: string;
          brand: string;
          imageUrl?: string | null;
          price: number;
        } | null;
        solarPanel?: {
          id: number;
          model: string;
          brand: string;
          imageUrl?: string | null;
          price: number;
          quantity?: number | null;
        } | null;
        inverter?: { id: number; model: string; brand: string; imageUrl?: string | null; price: number } | null;
        battery?: { id: number; model: string; brand: string; imageUrl?: string | null; price: number } | null;
      };
    }>;
  };
};

export const ProductUsageFragmentDoc = `
    fragment ProductUsage on ProductUsage {
  produced
  bought
  sold
  used
  usedPercentage
  consumptionPerMonth
  productionPerMonth
  totalPrice
  domesticPrice
  fuelPrice
  boughtPrice
  netEarning
  soldPrice
  domesticDiscount
  electricityDiscount
  totalEmission
}
    `;
export const OfferTypeSavingsFragmentDoc = `
    fragment OfferTypeSavings on OfferSavingsWithProducts {
  savings {
    after {
      ...ProductUsage
    }
    before {
      ...ProductUsage
    }
    saved {
      price
      emission
    }
  }
  products {
    price
    heatPumpOutdoorUnit {
      id
      price
      brand
      model
      imageUrl
    }
    solarPanel {
      id
      price
      brand
      model
      imageUrl
      quantity
    }
    inverter {
      id
      price
    }
    battery {
      id
      price
    }
    heatPumpIndoorUnit {
      id
      price
    }
  }
  types
  paybackMonths
}
    `;
export const BbrLookupByAddressDocument = `
    query bbrLookupByAddress($address: AddressInput!) {
  bbrLookupByAddress(address: $address) {
    type
    floors
    residents
    builtYear
    renovatedYear
    renovatedDegree
    area
    areaHeated
    areaNotHeated
    energyExpenditure
    heatDistribution
    waterConsumption
    primaryHeating {
      heatSource
      annualUsage
      price
      efficiency
      fuelType
      fuelSubtype
      volumeUnit
    }
    secondaryHeating {
      heatSource
    }
    roofs {
      orientation
      planeWidth
      planeDepth
      height
      maxPanelCoverage
      shadows
      isGuessed
    }
  }
}
    `;

export const useBbrLookupByAddressQuery = <TData = BbrLookupByAddressQuery, TError = unknown>(
  client: GraphQLClient,
  variables: BbrLookupByAddressQueryVariables,
  options?: UseQueryOptions<BbrLookupByAddressQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<BbrLookupByAddressQuery, TError, TData>(
    ['bbrLookupByAddress', variables],
    fetcher<BbrLookupByAddressQuery, BbrLookupByAddressQueryVariables>(
      client,
      BbrLookupByAddressDocument,
      variables,
      headers
    ),
    options
  );
};

useBbrLookupByAddressQuery.getKey = (variables: BbrLookupByAddressQueryVariables) => ['bbrLookupByAddress', variables];

export const EnergyOptionsDocument = `
    query energyOptions($residenceId: Int) {
  energyOptions(residenceId: $residenceId) {
    code
    denominator
    numerator
    valueNumber
  }
}
    `;

export const useEnergyOptionsQuery = <TData = EnergyOptionsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: EnergyOptionsQueryVariables,
  options?: UseQueryOptions<EnergyOptionsQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<EnergyOptionsQuery, TError, TData>(
    variables === undefined ? ['energyOptions'] : ['energyOptions', variables],
    fetcher<EnergyOptionsQuery, EnergyOptionsQueryVariables>(client, EnergyOptionsDocument, variables, headers),
    options
  );
};

useEnergyOptionsQuery.getKey = (variables?: EnergyOptionsQueryVariables) =>
  variables === undefined ? ['energyOptions'] : ['energyOptions', variables];

export const InsertAndSendOffersDocument = `
    mutation insertAndSendOffers($heatPumpOffers: [HeatPumpOfferInput!], $solarOffers: [SolarOfferInput!], $customer: CustomerInput!, $residence: ResidenceInput!, $address: AddressInput!, $dataConsent: DataConsentInput) {
  insertAndSendOffers(
    heatPumpOffers: $heatPumpOffers
    solarOffers: $solarOffers
    customer: $customer
    residence: $residence
    address: $address
    dataConsent: $dataConsent
  )
}
    `;

export const useInsertAndSendOffersMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<InsertAndSendOffersMutation, TError, InsertAndSendOffersMutationVariables, TContext>,
  headers?: RequestInit['headers']
) => {
  return useMutation<InsertAndSendOffersMutation, TError, InsertAndSendOffersMutationVariables, TContext>(
    ['insertAndSendOffers'],
    (variables?: InsertAndSendOffersMutationVariables) =>
      fetcher<InsertAndSendOffersMutation, InsertAndSendOffersMutationVariables>(
        client,
        InsertAndSendOffersDocument,
        variables,
        headers
      )(),
    options
  );
};

export const AddressSavingsDocument = `
    query addressSavings($dawaId: String!, $residence: HouseInputWithUsage) {
  addressSavings(dawaId: $dawaId, residence: $residence) {
    results {
      savings {
        saved {
          price
          emission
          priceByYear
        }
      }
      types
      paybackMonths
      products {
        price
        heatPumpIndoorUnit {
          id
          model
          brand
          imageUrl
          price
        }
        heatPumpOutdoorUnit {
          id
          model
          brand
          imageUrl
          price
        }
        solarPanel {
          id
          model
          brand
          imageUrl
          price
          quantity
        }
        inverter {
          id
          model
          brand
          imageUrl
          price
        }
        battery {
          id
          model
          brand
          imageUrl
          price
        }
      }
    }
  }
}
    `;

export const useAddressSavingsQuery = <TData = AddressSavingsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AddressSavingsQueryVariables,
  options?: UseQueryOptions<AddressSavingsQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<AddressSavingsQuery, TError, TData>(
    ['addressSavings', variables],
    fetcher<AddressSavingsQuery, AddressSavingsQueryVariables>(client, AddressSavingsDocument, variables, headers),
    options
  );
};

useAddressSavingsQuery.getKey = (variables: AddressSavingsQueryVariables) => ['addressSavings', variables];
